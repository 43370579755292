<template>
  <v-tooltip bottom>
    <template v-slot:activator="{ on, attrs }">
      <v-btn icon @click="exportToCsv" v-bind="attrs && $attrs" v-on="on">
        <v-icon>fas fa-file-csv</v-icon>
      </v-btn>
    </template>
    <span>Export as csv</span>
  </v-tooltip>
</template>

<script>
import { ExportToCsv } from "export-to-csv";
export default {
  name: "export_to_csv_btn",
  methods: {
    exportToCsv() {
      let body = [],
        headers = [];
      this.data.forEach((employee, dataIndex) => {
        let data = [];
        if (dataIndex === 0) {
          headers.push("#");
        }
        this.labels.forEach((label, index) => {
          if (index === 0) {
            data.push(dataIndex + 1);
          }
          data.push(employee[label["field"]]);
          if (!headers.includes(label["headerName"])) {
            headers.push(label["headerName"]);
          }
        });
        body.push(data);
      });
      const options = {
        fieldSeparator: ",",
        quoteStrings: '"',
        decimalSeparator: ".",
        showLabels: true,
        useTextFile: false,
        useBom: true,
        filename: this.$options.filters.fileName(this.name),
        headers
      };
      const csvExporter = new ExportToCsv(options);
      csvExporter.generateCsv(body);
    }
  },
  props: {
    data: {
      type: Array,
      default: () => []
    },
    labels: {
      type: Array,
      default: () => []
    },
    name: {
      type: String,
      default: "test"
    }
  }
};
</script>

<style scoped></style>
